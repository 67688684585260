import API from './axiosClient';
import config from './../config';

const URL =  config.apiURL;

function getPlanningProjects() {
    console.log('url', URL);
    return API.get(URL + '/planning-projects');
}

function addPlanningProjects(data) {
    return API.post(URL + '/planning-projects', data);
}

function getPlanningProject(id, data) {
    return API.get(URL +`/planning-projects/${id}`, data);
}

function putPlanningProjects(id, data) {
    return API.put(URL +`/planning-projects/${id}`, data);
}

function generateSSLPlanningProject(id, data) {
    return API.post(URL +`/planning-projects/${id}/generate-cert-ssl`, data);
}

function deletePlanningProjects(id) {
    return API.delete(URL +`/planning-projects/${id}`);
}

export default {
    getPlanningProjects,
    addPlanningProjects,
    putPlanningProjects,
    deletePlanningProjects,
    getPlanningProject,
    generateSSLPlanningProject
};