<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title>Créer Un Projet</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Nom de l'application <span lass="required">*</span></label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="applicationName"
                  v-model="planningProject.applicationName"
                  :prepend-inner-icon="icons.mdiApplication"
                  outlined
                  dense
                  placeholder="Nom de l'application"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">URL de l'application <span lass="required">*</span></label>
              </v-col>


              <v-col cols="12" md="9">
                <v-text-field
                  id="URL de l'application"
                  v-model="planningProject.domainName"
                  :prepend-inner-icon="icons.mdiWeb"
                  outlined
                  dense
                  placeholder="URL de l'application"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">URL de LastMile <span lass="required">*</span></label>
              </v-col>


              <v-col cols="12" md="9">
                <v-text-field
                  id="URL de lastMile"
                  v-model="planningProject.lastMileUrl"
                  :prepend-inner-icon="icons.mdiWeb"
                  outlined
                  dense
                  placeholder="URL de LastMile"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="email">Email de l'admin <span lass="required">*</span></label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="email"
                  v-model="planningProject.email"
                  outlined
                  :prepend-inner-icon="icons.mdiEmailOutline"
                  dense
                  placeholder="Email"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="email">API Key <span lass="required">*</span></label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="email"
                  v-model="planningProject.apiKey"
                  outlined
                  :prepend-inner-icon="icons.mdiApi"
                  dense
                  placeholder="API Key"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <label for="firstnameHorizontalIcons">Logo</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.logo"
                  :prepend-inner-icon="icons.mdiCameraImage"
                  outlined
                  dense
                  placeholder="Logo"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="firstnameHorizontalIcons">Formulaires désactivés ISC</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-text-field
                  id="firstnameHorizontalIcons"
                  v-model="planningProject.disabledForms"
                  :prepend-inner-icon="icons.mdiCameraImage"
                  outlined
                  dense
                  placeholder="Formulaires désactivés ISC"
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut notification email</label>
              </v-col>
              <v-col cols="12" md="9">
                <v-checkbox
                  id="flagEmail"
                  class="mt-0"
                  v-model="planningProject.flagEmail"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut notification agenda</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-checkbox
                  id="flagTaskAgenda"
                  class="mt-0"
                  v-model="planningProject.flagTaskAgenda"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col cols="12" md="3">
                <label for="emailHorizontalIcons">Statut facture impayée</label>
              </v-col>

              <v-col cols="12" md="9">
                <v-checkbox
                  id="flagAlertInvoice"
                  class="mt-0"
                  v-model="planningProject.flagAlertInvoice"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col offset-md="3" cols="9">
                <v-alert
                  style="margin-top:20px;margin-bottom:0px"
                  :color="statutAlert === 'INFO' ? 'primary' : 'error'"
                  v-show="message"
                  dense
                  dismissible
                  outlined
                  prominent
                  :type="statutAlert === 'INFO' ? 'info' : 'error'"
                  >{{ message }}</v-alert
                >
              </v-col>
              <v-col offset-md="3" cols="12">
                <v-btn color="primary" @click="savePlanningProject">
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiEmailOutline, mdiCameraImage, mdiWeb, mdiApplication, mdiApi } from '@mdi/js'
import { ref } from '@vue/composition-api'
import PlanningProjectAPI from '../../api/PlanningProjectAPI'
import handleErrorResponse from '../../utils'
export default {
  methods: {
    savePlanningProject() {
      this.statutAlert = 'INFO'
      this.message = 'La création du projet planning est en cours ...'
      PlanningProjectAPI.addPlanningProjects(this.planningProject)
        .then(response => {
          console.log(response)
          this.statutAlert = 'INFO'
          this.message = 'Le projet planning a été créé avec succès ! '
          setTimeout(() => this.$router.push({ path: '/' }), 1500)
        })
        .catch(e => {
          console.log('error', e)
          this.statutAlert = 'ERROR'
          this.message = (e && e.message) || e
        })
    },
  },
  data() {
    return {
      planningProject: {
        applicationName: '',
        domainName: '',
        lastMileUrl: '', 
        apiKey: '',
        email: '',
        logo: '',
        flagEmail: false,
        flagTaskAgenda: false,
        flagAlertInvoice: false,
      },
      message: '',
      statutAlert: 'ERROR',
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiWeb,
        mdiApplication,
        mdiApi,
        mdiCameraImage,
      },
    }
  },
}
</script>
